<template>
  <v-container>

    <NavBar v-if="loadMounted == true" :step="5" :typePlan="userData.typePlan"></NavBar>

    <Titulo></Titulo>

    <br>
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="5">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">

                  <br>
                  <br>

                  <h3 class="text-center labelIngresar">
                    Ingresa tus datos
                  </h3>

                  <h4 class="text-center labelSubingresar">
                    Son necesarios para completar la póliza del seguro
                  </h4>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.name" :rules="nameRules" label="Nombre" required
                    disabled @change="change()" maxlength="51" filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.surname" :rules="surnameRules" label="Apellido"
                    disabled @change="change()" maxlength="51" required filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.email" :rules="emailRules" label="Email" required
                    disabled @change="change()" maxlength="51" filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.phoneNumber" :rules="phoneRules" prefix="+569"
                    @change="change()" :counter="8" maxlength="8" label="Teléfono" required filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model.lazy="newRut" :rules="rutRules(newRut)" @change="change()"
                    maxLength="12" label="Ingresa tu RUT" required filled solo @input="changeRut"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.serialNumber" :rules="serialNumberRules"
                    @change="change()" maxlength="10" label="Ingresa el número de Documento / Serie" required filled
                    solo>
                  </v-text-field>

                  <br>

                  <v-select v-if="userData.salud == 'Isapre'" class="labelsDatos" :items="isapreTypes"
                    v-model="userData.typeIsapre" label="Tipo Isapre" :rules="typeIsapreRules" return-object required
                    filled solo></v-select>

                  <br v-if="userData.salud == 'Isapre'">

                  <v-text-field class="labelsDatos" v-model="userData.address" :rules="addressRules" maxlength="51"
                    @change="change()" label="Ingresar nombre de calle" required filled solo></v-text-field>

                  <br>

                  <div class="number-address">
                    <v-text-field class="labelsDatos-address" v-model="userData.addressNumber"
                      :rules="NumberRulesAddress" maxlength="51" :label="addressNumber" required filled solo
                      @change="change()"></v-text-field>
                    <v-text-field :rules="NumberRulesAddress2" class="labelsDatos-address"
                      v-model="userData.addressDepto" maxlength="4" :label="addressDepto" filled solo
                      @change="change()"></v-text-field>
                  </div>

                  <br>
                  <v-select class="labelsDatos" :items="regions" v-model="userData.region" :rules="regionRules"
                    @change="change()" :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object filled solo></v-select>

                  <br>
                  <v-select class="labelsDatos" :items="communes" v-model="userData.commune" :rules="communeRules"
                    @change="change()" :loading="loadingCommunes"
                    :disabled="loadingCommunes || userData.region === null" item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object filled solo>
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn text @click="back()"> Atrás </v-btn>

                  </div>

                </v-col>

              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import Titulo from "../components/Titulo.vue"
import RutUtils from "@/utils/rut.utils.js";
import NavBar from "@/components/NavBar.vue";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    loadMounted: false,
    screen: window.screen.width,
    stepperColor: '#FFB600',
    stepId: null,
    TipoPlan: null,
    e1: 5,
    valid: false,
    // isapreTypes: ["Banmédica", "Chuquicamata", "Colmena", "Consalud", "Cruz Blanca", "Cruz del Norte", "Nueva Masvida", "Fundación", "Fusat", "Río Blanco", "San Lorenzo Ltda.", "Vida Tres"],
    isapreTypes: ["Banmédica", "Chuquicamata", "Colmena", "Consalud", "Cruz Blanca", "Nueva Masvida", "Fundación", "Fusat", "San Lorenzo Ltda.", "Vida Tres", "Santa María", "Más Salud"],
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      address: null,
      email: null,
      phoneNumber: null,
      typeIsapre: null,
      salud: null,
      addressNumber: null,
      addressDepto: '',
      rutBenef: null,
    },
    rutIsDuplicate: false,
    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "Debes ingresar tu dirección",
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/.test(v) || "Ingrese solo números y letras"
    ],
    typeIsapreRules: [(v) => !!v || "Debes ingresar tu tipo de ISAPRE",],
    regionRules: [(v) => !!v || "Debes ingresar tu región",],
    communeRules: [(v) => !!v || "Debes ingresar tu comuna",],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 carácteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números"
    ],
    NumberRulesAddress: [
      (v) => !!v || "N° de calle requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 carácteres",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "N° de calle requerido",
      (v) => /^[0-9]+$/.test(v) || "Ingrese solo números"
    ],
    NumberRulesAddressNumber: [
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ]+$/.test(v) || "Ingrese solo números y letras"
    ],
    NumberRulesAddress2: [
      (v) => !v || /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ]+$/.test(v) || "Ingrese solo números y letras"
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    phoneRules: [
      (v) => !!v || "Número telefónico requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "El nombre es requerido"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "El apellido es requerido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    regions: [],
    communes: [],
    newRut: "",

  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.rutBenef = localStorage.getItem('benefRuts');
    this.stepId = localStorage.getItem('stepId');
    this.userData.name = localStorage.getItem('name');
    this.userData.surname = localStorage.getItem('surname');
    this.userData.email = localStorage.getItem('email');
    this.userData.phoneNumber = localStorage.getItem('phoneNumber');
    // this.userData.rut = localStorage.getItem('rut');
    // this.newRut = localStorage.getItem('rut');
    this.userData.serialNumber = localStorage.getItem('serialNumber');
    this.userData.address = localStorage.getItem('address');
    this.userData.salud = localStorage.getItem('salud');
    this.userData.addressNumber = localStorage.getItem('addressNumber');
    this.userData.region = localStorage.getItem('region');
    this.userData.commune = localStorage.getItem('commune');
    if (localStorage.getItem('addressDepto') == 'null') { this.userData.addressDepto = '' } else { this.userData.addressDepto = localStorage.getItem('addressDepto') }
    this.userData.typePlan = localStorage.getItem('typePlan');


    if(localStorage.getItem('rut') != 'null' && localStorage.getItem('rut') != null) {
      this.changeRut(localStorage.getItem('rut'));
    }

    if (localStorage.getItem('typeIsapre')) {
      this.userData.typeIsapre = localStorage.getItem('typeIsapre')
    }

    if (!this.userData.name || !this.userData.surname || !this.userData.email) {

      swal({
        title: "¡Falta información!",
        text: "Por favor, complete nuevamente el flujo.",
        icon: "info",
        button: "Ok",
      }).then(() => {
        this.$router.push({ path: '/' });
      });
    }

    if (this.screen > 800) {
      this.vista = 'vista2'
      this.TipoPlan = 'PlanEscritorio'
      this.addressNumber = 'Ingresa tu número de calle'
      this.addressDepto = 'Ingresa tu número de depto'
    } else {
      this.vista = 'vista'
      this.TipoPlan = 'PlanMovil'
      this.addressNumber = 'Número de calle'
      this.addressDepto = 'Número de depto'
    }
    this.getRegions();
    this.getRegionSelected(this.userData.region)
    this.loadMounted = true;
  },
  methods: {
    rutRules(v) {
      return [
        (v) => !!v || 'Debes ingresar tu RUT',
        (v) => /^[0-9kK.-]+$/.test(v) || 'No se permiten carácteres especiales',
        (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
        RutUtils.validateRut(v) || "Rut no válido",
        RutUtils.getCondition(v, this.rutIsDuplicate) || "RUT coincide con beneficiario"
      ];
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getRegionSelected(region) {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions(region);
      for (let i = 0; i < this.regions.length; i++) {
        if (this.regions[i].id == this.userData.region) {
          this.userData.region = this.regions[i]
          this.communes = this.regions[i]['communes']
          break;
        }
      }
      for (let i = 0; i < this.communes.length; i++) {
        if (this.communes[i].id == this.userData.commune) {
          this.userData.commune = this.communes[i]
          break;
        }
      }
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      // console.log('rut-benef:', this.rutBenef)
      if (this.rutBenef !== '' && this.rutBenef !== null && this.userData.typePlan == 'Familiar') {
        const rutValues = this.rutBenef.split(';');
        for (let i = 0; i < rutValues.length; i++) {
          let currentValue = rutValues[i].replace(/[.-]/g, '');
          rut = rut.replace(/[.-]/g, '');
          if (!/\d/.test(currentValue)) {
            continue;
          }
          // console.log('currentValue:',currentValue)
          if (currentValue === rut && localStorage.getItem('typePlan') != 'Individual') {
            this.rutIsDuplicate = true;
            console.log("Error: no puede");
            swal({
              title: "Atención!",
              text: "Tu RUT no puede coincidir con el de algún beneficiario!",
              icon: "info",
              button: "De acuerdo",
            });
            break;
          } else {
            this.rutIsDuplicate = false;
          }
        }
      } else {
        this.rutIsDuplicate = false;
      }
      this.aers = RutUtils.getCondition(rut, this.rutIsDuplicate);
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    SaveDates(dates) {
      this.$refs.menu.save(dates)
    },
    validate() {
      // console.log(this.rutIsDuplicate, "rut status")

      // console.log(this.$refs.form.validate(), "validate")
      if (this.$refs.form.validate() && this.rutIsDuplicate === false) {
        // console.log("llegue")
        localStorage.setItem("name", this.userData.name);
        localStorage.setItem("surname", this.userData.surname);
        localStorage.setItem("email", this.userData.email);
        localStorage.setItem("phoneNumber", this.userData.phoneNumber);
        localStorage.setItem("rut", this.userData.rut);
        localStorage.setItem("serialNumber", this.userData.serialNumber);
        localStorage.setItem("address", this.userData.address);
        localStorage.setItem("typeIsapre", this.userData.typeIsapre);
        localStorage.setItem("commune", this.userData.commune.id);
        localStorage.setItem("region", this.userData.region.id);
        localStorage.setItem("communeCode", this.userData.commune.code);
        localStorage.setItem("regionCode", this.userData.region.code);
        localStorage.setItem("addressNumber", this.userData.addressNumber);
        if (this.userData.addressDepto === null || this.userData.addressDepto === '') {
          localStorage.setItem("addressDepto", '');
        } else {
          localStorage.setItem("addressDepto", this.userData.addressDepto);
        }
        this.$router.push({ path: '/resumen' })
      }
    },
    back() {
      if (this.userData.typePlan == 'Individual') {
        this.$router.push({ path: '/dps' })
      } else {
        this.$router.push({ path: '/datos-beneficiarios' })
      }
    },
    async change() {
      var values;
      try {
        values = [
          this.userData.name,
          this.userData.surname,
          this.userData.serialNumber,
          this.userData.rut,
          this.userData.region != null ? this.userData.region.name : '',
          this.userData.commune != null ? this.userData.commune.name : '',
          this.userData.address,
          this.userData.email,
          this.userData.phoneNumber,
          this.userData.addressNumber,
          this.userData.addressDepto != null ? this.userData.addressDepto : '',
        ]
        var carro = await ShieldService.createStep(values, 4, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.labelIngresar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  margin-top: -5%;
}

.labelSubingresar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelsDatos {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

.number-address {
  display: flex;
  gap: 10px;
}

.labelsDatos-address {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.v-input.labelsDatos.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed.v-select div.v-input__control div.v-text-field__details div.v-messages.theme--light div.v-messages__wrapper div.v-messages__message {
  color: red;
}

html body div.swal-overlay.swal-overlay--show-modal div.swal-modal div.swal-text {
  text-align: center;
}
</style>