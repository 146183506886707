<template>
    <v-container>
        <v-row justify="center">
            <v-dialog v-model="dialog" width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                    </v-card-title>
                    <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus modificaciones, autorizo expresamente a Southbridge Compañía de Seguros Generales S.A. para
                        que haga tratamiento, almacene, transmita y comunique mis datos personales y la información que
                        le he proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas, sociedades del Grupo Empresarial al que pertenece y terceros prestadores de
                        servicios, estén ubicados dentro o fuera de Chile, con el propósito de que tanto Southbridge
                        Compañía de Seguros Generales S.A. como dichas entidades i) me contacten y pongan a mi
                        disposición, en la forma que estimen pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi información personal para los objetivos del cumplimiento de este Contrato de Seguro; y iii)
                        hagan efectivos el o los convenios que pudieren estar asociados a los seguros que he contratado.
                        Además, por este acto, autorizo expresamente a que cualquier información, comunicación,
                        declaración o notificación que debe efectuar Southbridge Compañía de Seguros Generales S.A. en
                        virtud del presente contrato de seguros sea realizada a través de correo electrónico o de
                        cualquier sistema de transmisión o registro digital de la palabra escrita o verbal o por
                        cualquier otro medio de comunicación fehaciente. El asegurado declara que conoce, acepta y concuerda
                        que cualquier transacción que se realice en este sitio con su Rut se
                        entenderá y se reputará, para todos los efectos
                        legales, como realizada por él mismo. En tal sentido, Southbridge Compañía de Seguros Generales S.A.
                        no tiene ninguna responsabilidad por el uso indebido de la información empleada por un tercero
                        distinto del asegurado. El asegurado se hace responsable por la veracidad de la información 
                        entregada para la contratación del seguro y del uso de las claves o códigos, liberando a Southbridge 
                        Compañía de Seguros Generales S.A. de responsabilidad por cualquier mal uso que hagan terceros de la
                        información que Southbridge Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del
                        asegurable.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="botonCondiciones" style="color: white;" elevation="0" text @click="dialog = false">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
  
        <br>
  
        <NavBar :step="6"></NavBar>
  
        <Titulo></Titulo>
  
        <br><br v-if="screen < 800">

        <v-stepper v-model="e1" elevation="0">
  
            <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Planes
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 5"
            step="5">Datos</v-stepper-step>
        </div>
      </v-stepper-header>
        <br><br><br>
        <br v-if="screen > 800">
        <br v-if="screen > 800">

        <div v-show="showTokuIframe">
  
            <div id="toku-container">
  
            </div>
  
        </div>
  
        <div v-if="loadingToku == true" class="text-center" style="margin-top:5%">
            <!-- <div v-if="loadingFinal == true" class="text-center" style="margin-top:5%"> -->
  
            <v-progress-circular :size="300" color="primary" indeterminate></v-progress-circular>
  
            <br>
            <br>
  
            {{ cont }}
  
        </div>
  
        <div v-else>
  
            <div v-if="!showTokuIframe" style="max-width: 500px; margin: auto;">
  
                <h3 class="text-center labelPaso">
                    ¡{{ userData.name }}, ESTAMOS A UN PASO!
                </h3>
  
                <br>
  
                <h4 class="text-center labelSubpaso">
                    Antes de contratar te mostramos un resumen de tu seguro oncológico
                </h4>
  
                <v-row>
  
                    <v-col>
  
                        <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" style="margin-left: 11%"
                            height="500px" max-width="100%">
                            <v-card-text class=" font-weight-medium mt-8 text-center text-subtitle-1 color:primary"></v-card-text>
                            <v-col :class="col">
                                <label class="labelEtiquetaResumen">Contratante: </label>
  
                                <label class="labelEtiqueta">{{ userData.name }} {{ userData.surname }}</label>
  
                                <br>
                                <br>
  
                                <label class="labelEtiquetaResumen">Rut: </label>
  
                                <label class="labelEtiqueta">{{ userData.rut }}</label>
  
                                <br>
                                <br>
  
                                <label class="labelEtiquetaResumen">Teléfono: </label>
  
                                <label class="labelEtiqueta">{{ userData.phoneNumber }}</label>
                                <br>
                                <br>
  
                                <label class="labelEtiquetaResumen">Email: </label>
  
                                <label class="labelEtiqueta">{{ userData.email }}</label>
  
                                <br>
                                <br>
  
                                <label class="labelEtiquetaResumen">Tipo de Plan: </label>
  
                                <label class="labelEtiqueta">{{ userData.typePlan }}</label>
  
                                <br>
                                <br>
  
                                <label class="labelEtiquetaResumen">Previsión de Salud: </label>
  
                                <label class="labelEtiqueta">{{ userData.salud }}</label>
  
                                <br>
                                <br>
  
                                <label class="labelEtiquetaResumen">Declaración de Salud: </label>
  
                                <label class="labelEtiqueta">Ok</label>
  
                                <br>
                                <br>

                                <div v-if="loadingPlans == true" :class="rectangulo" style="text-align:center;">
  
                                    <label class="labelPrecioPlan">{{ formatPrice(planData[0].values[0].priceCLP) }}</label>
  
                                    <label class="labelPrecioUf">/ UF {{ (planData[0].price).toFixed(2) }} mensual</label>
  
                                </div>
  
                                <div class="lineaIzquierda"></div>
  
                                <div class="lineaDerecha"></div>
  
                                <br>
                                <br>
  
                                <h3 class="text-center labelFecha">*De acuerdo al valor UF del día {{ fechaActual }}</h3>
  
                            </v-col>
  
                        </v-card>
  
                        <v-form ref="form" v-model="valid" max-witdh="300px" :class="termsConditions">
  
                            <v-hover>
                                <v-checkbox :class="vista" :rules="conditionsRules" :color="stepperColor" required>
                                    <template v-slot:label>
                                        <p :class="terminos">Acepto los
                                            <b>
                                                <a style="color:rgba(0, 44, 119, 1)" @click="dialog = true">Términos y
                                                    Condiciones</a>
                                            </b>
                                        </p>
                                    </template>
                                </v-checkbox>
                            </v-hover>
  
  
                        </v-form>
  
                        <div :class="buttonContainer">
                            <v-btn
                                style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 120%; margin-left: 5%;"
                                @click="validate()">
                                Ir a pagar
                            </v-btn>
                        </div>
  
                    </v-col>
  
                </v-row>
  
            </div>
  
            <br>
  
        </div>
      </v-stepper>
    </v-container>
  </template>
  
  <script>
  import ShieldService from "@/services/shield.service.js";
  import Titulo from "../components/Titulo.vue";
  import NavBar from "@/components/NavBar.vue";
  import swal from 'sweetalert';
  
  export default {
    data() {
        return {
            personId: '',
            showTokuIframe: false,
            toku: null,
            beneficiaries: [],
            lengthBenef: null,
            edadMayor: null,
            userData: {
                name: null,
                surname: null,
                serialNumber: null,
                rut: null,
                typeIsapre: null,
                region: null,
                commune: null,
                comuneCode: null,
                regionCode: null,
                address: null,
                email: null,
                phoneNumber: null,
                couponId: null,
                stepId: null,
                old: null,
                dates: null,
                salud: null,
                questionOne: null,
                questionTwo: null,
                questionThree: null,
                typePlan: null,
                addressNumber: null,
                addressDepto: null,
            },
            stepId: null,
            terminos: null,
            dialog: false,
            name: "Test",
            fechaActual: null,
            cont: "Cargando.  ",
            loading: false,
            loadingToku: false,
            loadingFinal: false,
            loadingPlans: false,
            screen: window.screen.width,
            formatter: new Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP",
            }),
            stepperColor: '#FFB600',
            planData: null,
            conditionsRules: [
                (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
            ],
        }
    },
    components: { Titulo, NavBar },
    async mounted() {
        this.e1 = 6;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
  
        this.lengthBenef = localStorage.getItem('cantBenef')
  
        if (!localStorage.getItem("rut") && !localStorage.getItem("serialNumber") && !localStorage.getItem("address") && !localStorage.getItem("addressNumber") && !localStorage.getItem("region") && !localStorage.getItem("commune") ) {
            swal({
            title: "¡Falta información!",
            text: "Por favor, complete nuevamente el flujo.",
            icon: "info",
            button: "Ok",
            }).then(() => {
            this.$router.push({ path: '/' });
            });
        } else{
            if (this.lengthBenef != null && this.lengthBenef > 0) {
                this.getBeneficiaries()
            }
            this.userData.name = localStorage.getItem("name")
            this.userData.surname = localStorage.getItem("surname")
            this.userData.serialNumber = localStorage.getItem("serialNumber")
            this.userData.rut = localStorage.getItem("rut")
            this.userData.typeIsapre = localStorage.getItem("typeIsapre")
            this.userData.region = localStorage.getItem("region")
            this.userData.commune = localStorage.getItem("commune")
            this.userData.regionCode = localStorage.getItem("regionCode"), // Nuevo
            this.userData.comuneCode = localStorage.getItem("communeCode"), // Nuevo
            this.userData.address = localStorage.getItem("address")
            this.userData.addressNumber = localStorage.getItem("addressNumber"),
            this.userData.addressDepto = localStorage.getItem("addressDepto"),
            this.userData.email = localStorage.getItem("email")
            this.userData.phoneNumber = localStorage.getItem("phoneNumber")
            this.userData.couponId = localStorage.getItem("couponId") == 'null' ? null : localStorage.getItem("couponId")
            this.userData.stepId = localStorage.getItem("stepId")
            this.stepId = localStorage.getItem("stepId")
            this.userData.old = localStorage.getItem("old")
            this.userData.dates = localStorage.getItem("dates")
            this.userData.salud = localStorage.getItem("salud")
            this.userData.questionOne = localStorage.getItem("Q1")
            this.userData.questionTwo = localStorage.getItem("Q2")
            this.userData.questionThree = localStorage.getItem("Q3")
            this.userData.typePlan = localStorage.getItem("typePlan")
    
            this.edadMayor = localStorage.getItem("edadMayor")
    
            this.loadingPlans = false
    
            this.planData = await ShieldService.getPlans(this.userData.salud, this.userData.typePlan, this.edadMayor, this.userData.couponId)
    
            this.loadingPlans = true
    
    
            this.calcularFecha()
            if (this.screen > 800) {
                this.vista = 'test'
                this.rectangulo = 'rectangulo'
                this.terminos = 'terminos'
                this.buttonContainer = 'button-container'
                this.termsConditions = 'termsConditions'
                this.col = 'col'
            } else {
                this.vista = 'test2'
                this.rectangulo = 'rectangulo2'
                this.terminos = 'terminos2'
                this.buttonContainer = 'button-container2'
                this.termsConditions = 'termsConditions2'
                this.col = 'col2'

            }
        }
        
    },
    methods: {
        calcularFecha() {
            var fecha = new Date().toISOString().substr(0, 10).split('-')
            this.fechaActual = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
        },
        formatPrice(number) {
            return this.formatter.format(number);
        },
        back() {
            this.$emit("back");
        },
        async validate() {
            if (this.$refs.form.validate()) {
                // Conectar con SHIELD
                this.loading = true;
                this.loadingFinal = true;
                this.cont = "Estamos Procesando tu Solicitud . . ."
                try {

                    this.loadingToku = true;
  
                    const res = await ShieldService.createInsurance(
                        this.userData,
                        this.beneficiaries,
                        this.planData,
                        this.stepId,
                    );

                    this.loadingToku = false;
  
                    window.location.href = res
    
                    // const res = await ShieldService.createInsuranceWithToku(
                    //     this.userData,
                    //     this.beneficiaries,
                    //     this.planData,
                    //     this.stepId,
                    // );
                    // if (res.personId) this.personId = res.personId
                    
                    // localStorage.setItem('personIdWaitingForToku', this.personId)
                    // window.location.href = res.redirectUrl
  
                } catch (error) {
                    this.loadingToku = false;
                    console.log("ERRRORRR: ", error)
                    this.loadingFinal = false;
                    this.loading = false;
                    window.location.pathname = '/error';
                }
            }
        },
        getBeneficiaries() {
            var contador = 0;
            var olds = localStorage.getItem('benefOlds') != null ? localStorage.getItem('benefOlds').split(';') : null;
            var types = localStorage.getItem('benefTypes') != null ? localStorage.getItem('benefTypes').split(';') : null;
            var names = localStorage.getItem('benefNames') != null ? localStorage.getItem('benefNames').split(';') : null;
            var surnames = localStorage.getItem('benefSurnames') != null ? localStorage.getItem('benefSurnames').split(';') : null;
            var ruts = localStorage.getItem('benefRuts') != null ? localStorage.getItem('benefRuts').split(';') : null;
            var days = localStorage.getItem('benefDays') != null ? localStorage.getItem('benefDays').split(';') : null;
            var months = localStorage.getItem('benefMonths') != null ? localStorage.getItem('benefMonths').split(';') : null;
            var years = localStorage.getItem('benefYears') != null ? localStorage.getItem('benefYears').split(';') : null;
  
  
            while (this.lengthBenef != contador) {
                var person = {
                    tipo: types != null ? types[contador] : '',
                    edad: olds != null ? olds[contador] : '',
                    name: names != null ? names[contador] : '',
                    surname: surnames != null ? surnames[contador] : '',
                    rut: ruts != null ? ruts[contador] : '',
                    year: years != null ? years[contador] : '',
                    month: months != null ? months[contador] : '',
                    day: days != null ? days[contador] : '',
                    date: null,
                    newRut: ruts != null ? ruts[contador] : '',
                }
                this.beneficiaries.push(person)
                contador++;
            }
        },
    }
  }
  </script>
  
  <style lang="scss">
  .col{
    margin:auto;
  }
  .col2{
    margin:auto;
  }
  .labelFecha {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #232323;
    opacity: 0.75;
    margin: auto;
  }
  
  .labelPrecioPlan {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: rgba(0, 44, 119, 1);
  }
  
  .labelPrecioUf {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: rgba(0, 44, 119, 1);
    opacity: 0.8;
  }
  
  .botonCondiciones {
    box-sizing: border-box;
    padding: 12px 24px;
    gap: 10px;
    background: #FFB600;
    border: 2px solid #FFB600;
    border-radius: 8px;
    margin-top: -6%;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
  }
  
  .labelPaso {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #FFB600;
    text-transform: uppercase;
  }
  
  .labelSubpaso {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: rgba(0, 44, 119, 1);
  }
  
  .labelEtiquetaResumen {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
  }
  
  .labelEtiqueta {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
  }
  
  .terminos {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
  }
  
  .terminos2 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
  }
  
  .test {
    margin-left: 25%;
    margin-top: 1%;
  }
  
  .test2 {
    margin-left: 15%;
    margin-top: 2%;
  }
  
  .v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
  }
  
  .rectangulo {
    box-sizing: border-box;
    // position: absolute;
    height: 30px;
    width: 200px;
    // border: 2px solid rgba(0, 44, 119, 1)1f;
    border-radius: 5px;
    margin: auto;
  }
  
  .linea {
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    border-top: 2px solid rgba(0, 44, 119, 1)1f;
    width: calc(24%);
    height: 0;
    background: rgba(0, 44, 119, 1);
    opacity: 0.15;
  }
  
  .lineaIzquierda {
    left: 0;
    width: 122px;
  }
  
  .lineaDerecha {
    right: 0;
    width: 120px;
  }
  
  .rectangulo2 {
    box-sizing: border-box;
    position: absolute;
    top: 81%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 40px;
    width: 250px;
    border: 2px solid rgba(0, 44, 119, 1)1f;
    border-radius: 5px;
    padding-top: 30px
  }
  
  .step-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    h1 {
        font-weight: 500;
        text-align: center;
        color: #464e71;
        margin-bottom: 2rem;
    }
  
    h2 {
        font-weight: 500;
        color: #464e71;
        margin-bottom: 1rem;
    }
  
    p {
        text-align: center;
    }
  
    .back-container {
        width: 100%;
        align-items: flex-start;
  
        .back-button {
  
  
            &:hover {
                opacity: 1;
            }
        }
    }
  
    .forecast-selector {
        text-transform: uppercase;
        font-weight: 600;
        color: #464e71;
    }
  }
.button-container {
    text-align: center;
    margin: auto;
}
.button-container2 {
    text-align: center;
    margin: auto;
}
.termsConditions{
    margin: auto;
}
.termsConditions2{
    margin: auto;
}
</style>